<template>
  <v-app-bar app color="primary" dark src="heartCloud.png">

    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,100,100,.5), rgba(250,100,100,.7)"
      ></v-img>
    </template>

    <v-icon left :color="getStatusColor" @click="selectHeart()">mdi-heart</v-icon>

    <v-tabs v-model="tab">
      <v-tab href="#Foto">FOTO</v-tab>
      <v-tab v-if="hasUser" href="#Quiz">QUIZ</v-tab>
      <v-tab v-if="isGod" href="#QuizResult">RESULT</v-tab>
      <v-tab v-if="isGod" href="#Users">USERS</v-tab>
      <v-tab v-if="isGod" href="#Settings">SETTINGS</v-tab>
      <v-file-input v-if="hasUser && isGod"
        prepend-icon="mdi-camera"
        hide-input
        multiple
        v-model="uploadFiles"
        class="mt-3 mr-6 ml-3"
      />
    </v-tabs>
    
    <v-file-input v-if="hasUser && !isGod"
      prepend-icon="mdi-camera"
      hide-input
      multiple
      v-model="uploadFiles"
    />
    <Info :show="showDialog" @close="showDialog = false"/>
  </v-app-bar>
</template>

<script>
import { uploadImages } from "@/base/upload.js";
import { getUserAndTeam } from "@/base/user.js"
import Info from './Info.vue'

export default {
  name: "TopBar",
  components: {
    Info
  },
  data: function () {
    return {
      showDialog:false,
      activeTab: "",
      uploadFiles: [],
      feedBack: { progress: 0, uploading: false },
      userAndTeam: undefined,
      updateInterval: undefined,
      resetCounter:0,
    };
  },
  watch: {
    async uploadFiles() {
      this.feedBack.progress = 0;
      this.feedBack.uploading = true;
      await uploadImages(this.uploadFiles, this.feedBack);
      this.feedBack.progress = 100;
      this.feedBack.uploading = false;
    },
    ["feedBack.progress"]() {
      this.$emit("feedBack", this.feedBack);
    }
  },
  methods: {
    selectHeart() {
      this.resetCounter++;
      setTimeout(() => this.resetCounter = 0, 5000);
      if (this.resetCounter > 10) {
        localStorage.clear();
        console.log("CLEARED");
      }
      this.showDialog = true;
    },
    async refreshUser() {
      this.refreshCounter++;
      this.userAndTeam = getUserAndTeam();
      this.userId = this
    }
  },
  computed: {
    hasUser() {
      return this.userAndTeam?.userId;
    },
    isGod() {
      return this.userAndTeam?.isGod;
    },
    getStatusColor() {
      if (this.isGod) {
        return "red";
      } else if (this.hasUser) {
        return "white";
      }
      return "grey";
    },
    tab: {
      set(tab) {
        this.$emit("MenuChange", tab);
        this.activeTab = tab;
      },
      get() {
        return this.activeTab;
      },
    },
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    this.refreshUser();
    this.updateInterval = setInterval(() => {
      this.refreshUser();
    }, 5000);
  }
};
</script>
