<template>
  <v-app>
    <TopBar @feedBack="handleFeedBack" @MenuChange="menuChange"/>
    <v-main :style="{backgroundColor: teamColor}">
      <v-container fluid>
        <Home v-show="activeTab === 'Foto'" :userAndTeam="userAndTeam"/>
        <Quiz v-if="activeTab === 'Quiz'" :userAndTeam="userAndTeam"/>
        <QuizResult v-if="activeTab === 'QuizResult'" :userAndTeam="userAndTeam"/>
        <Users v-if="activeTab === 'Users'" :userAndTeam="userAndTeam"/>
        <Settings v-if="activeTab === 'Settings'" :userAndTeam="userAndTeam"/>
      </v-container>
    </v-main>
    <div class="topProgress">
      <v-progress-linear v-if="feedBack && feedBack.uploading" v-model="feedBack.progress" color="black" />
    </div>
  </v-app>
</template>

<script>
import Home from './components/Home'
import Quiz from './components/Quiz'
import QuizResult from './components/QuizResult'
import Users from './components/Users'
import TopBar from './components/TopBar'
import Settings from './components/Settings'
import { setUserId, setTeamId, getTeamId, getUserId, generateUserId, updateUser, getUserAndTeam, getUserInfo } from "@/base/user.js"

export default {
  name: 'App',

  components: {
    TopBar,
    Home,
    Quiz,
    QuizResult,
    Users,
    Settings,
  },

  data: function() {
    return {
      feedBack: undefined,
      userAndTeam: undefined,
      activeTab: "Foto",
      updateInterval: undefined,
      refreshCounter: 0,
    }
  },
  computed: {
    teamColor() {
      return this.userAndTeam?.team?.color;
    }
  },
  methods: {
    menuChange(tab) {
      this.activeTab = tab;
    },
    handleFeedBack(feedBack) {
      this.feedBack = feedBack;
    },
    async registerUser(passCode, teamId) {
      const desiredTeamId = teamId || getTeamId();
      const desiredUserId = getUserId() || generateUserId();
      setUserId(desiredUserId);
      setTeamId(desiredTeamId);
      this.userAndTeam = await updateUser(passCode);
    },
    async refreshTeamAndUser() {
      this.refreshCounter++;
      if (this.refreshCounter % 6 === 0) await getUserInfo();
      this.userAndTeam = getUserAndTeam();
    }
  },
  destroyed() {
    clearInterval(this.updateInterval);
  },
  mounted() {
    let params = new URLSearchParams(window.location.search.substring(1));
    const passCode = params.get("gurka");
    const teamId = params.get("x")
    if (passCode) {
      this.registerUser(passCode, teamId);
      window.history.pushState({}, document.title, window.location.pathname);
    } else {
      this.userAndTeam = getUserAndTeam();
    }
    this.updateInterval = setInterval(() => {
      this.refreshTeamAndUser();
    }, 5000);
  }
};
</script>
<style>
.top-frame {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-style: solid;
  border-width: 2px 6px;
  z-index: 10;
}
.topProgress {
  position: absolute;
  min-height: 8px;
  left: 0;
  top: 0;
  right: 0;
  z-index: 250;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
